<template>
  <Table :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="!elements.isEmpty()">
      <TableElement @click="$emit('details', element)"
                    :class="element.getId() % 2 == 0 ? 'even': 'odd'" v-for="element in elements.getItems()"
                    v-bind:key="element.getId()">
        <TableElementItem>
          <template v-slot:default>
            {{ element.getName() }}
          </template>
        </TableElementItem>
        <TableElementItem class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ element.getEmail() }}
          </template>
        </TableElementItem>
        <TableElementItem class="d-none d-lg-table-cell">
          <template v-slot:default>
            <span class="d-inline-flex pr-1 comma" v-for="role in element.getRoles()" :key="role">
              {{ $t(role) }}
            </span>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Status :status="element.isActive() ? 'users.active' : 'users.inactive'" />
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('users.list.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {ScreenSizeMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Status from "@/components/elements/Status";

export default {
  name: "UserTable",
  mixins: [ScreenSizeMixin, NumbersMixin],
  emits: ['confirm', 'details'],
  components: {Status, TableElement, TableElementItem, Table},
  props: {
    elements: Object
  },
  data() {
    return {
      allHeaders: [
        'users.list.name',
        'users.list.email',
        'users.list.roles',
        'users.list.status',
      ],
      responsiveHeaders: [
        'users.list.name',
        'users.list.status',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    pagination: function () {
      return this.elements.getPagination();
    }
  },
}
</script>

<style scoped>
.comma:not(:first-child) {
  margin-left: -.3em;
}

.comma:first-child:empty ~ .comma:not(:empty) {
  margin-left: 0;
}

.comma:first-child:empty ~ .comma:not(:empty) ~ .comma:not(:empty) {
  margin-left: -.3em;
}

.comma:empty {
  display: none;
}

.comma:not(:first-child):before {
  content: " , ";
}

.comma:empty + .comma:not(:empty):before {
  content : "";
}

.comma:not(:empty) ~ .comma:empty + .comma:not(:empty):before {
  content : " , ";
}
</style>
<template>
  <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <UserModal @closemodal="closeUserModal" :user="selectedUser" v-if="selectedUser"/>
  <Card :title="$t('users.list.title')">
    <template v-slot:default>
      <UserTable @page="getUsers" @details="showUserDetails" :elements="users"/>
    </template>
    <template v-slot:footer>
      <Button @click="createNewUser" type="confirm" name="users.buttons.create" class="mb-0" />
    </template>
  </Card>
</template>

<script>
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import {ConfirmationModalMixin} from "@/mixins/ModalMixin";
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import UserTable from "@/components/user/UserTable";
import LoadableCollection from "@/entities/LoadableCollection";
import Pagination from "@/entities/Pagination";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import User from "@/entities/User";
import UserModal from "@/components/user/UserModal";

export default {
  name: "UserList",
  components: {UserModal, UserTable, Button, Card, ConfirmationModal},
  mixins: [ConfirmationModalMixin, LoadingModalMixin],
  data: function () {
    return {
      users: new LoadableCollection(),
      selectedUser: null,
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    closeUserModal: function() {
      this.selectedUser = null;
      this.getUsers();
    },
    getUsers: function (pageNr = 1) {
      this.hydrateUsers(this.users, null, pageNr);
    },
    showUserDetails: function (user) {
      this.selectedUser = user;
    },
    createNewUser: function () {
      this.selectedUser = new User();
    },
    hydrateUsers(users, filter, pageNr = 1) {
      let that = this;
      let localUsers = new LoadableCollection();
      let filterParams = {
        page: pageNr,
      };

      this.queueJob();
      this.axios.get(this.$store.state.config.getUsersUri(), {params: filterParams}).then(
          response => {
            let content = response.data;
            localUsers.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (user) {
              let localUser = new User(user);
              localUser.setLoaded(true);
              localUsers.push(localUser);
            });

            localUsers.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        if (localUsers.isLoaded()) {
          users.empty();
          users.setPagination(localUsers.getPagination());
          localUsers.getItems().forEach(user => users.push(user));
          users.loaded();
        }
        that.completeJob();
      });
    },
  }
}
</script>

<style scoped>

</style>